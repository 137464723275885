/*
 * @Author: xiaodongyu
 * @Date 2019-05-12 22:01:16
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2019-05-12 22:01:36
 */

export default {
    TBK: 'app_config.cash_loan_tbk_90'
};
