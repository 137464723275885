/*
 * @Author: liuzhenkun
 * @Date: 2019-04-15 11:53:38
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2019-05-12 22:00:22
 */

import http from 'ssr-common/vue/vue-http';

export const api = {
    getStaticTextByKey: '/api/operation/staticText/getByKey',

    getGeneralConfig: '/api/generalConfig'
};

export default {
    getStaticTextByKey(key) {
        return http.get(api.getStaticTextByKey, {params: {key}});
    },

    getGeneralConfig(params) {
        return http.get(api.getGeneralConfig, {params});
    }
};
