var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "agile",
      class: {
        "agile--fade": _vm.settings.fade && !_vm.settings.unagile,
        "agile--disabled": _vm.settings.unagile,
      },
    },
    [
      _c("div", { ref: "list", staticClass: "agile__list" }, [
        _c(
          "div",
          {
            ref: "track",
            staticClass: "agile__track",
            style: {
              width: _vm.width.track + "px",
              transform: "translate(-" + _vm.transform + "px)",
              transition:
                "transform " +
                _vm.settings.timing +
                " " +
                _vm.transitionDelay +
                "ms",
            },
            on: {
              mouseover: function ($event) {
                return _vm.handleMouseOver("track")
              },
              mouseout: function ($event) {
                return _vm.handleMouseOut("track")
              },
            },
          },
          [_vm._t("default")],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.settings.dots && !_vm.settings.unagile
        ? _c(
            "ul",
            { ref: "dots", staticClass: "agile__dots" },
            _vm._l(_vm.slidesCount, function (n) {
              return _c(
                "li",
                {
                  key: n,
                  staticClass: "agile__dot",
                  class: { "agile__dot--current": n - 1 === _vm.currentSlide },
                  on: {
                    mouseover: function ($event) {
                      return _vm.handleMouseOver("dot")
                    },
                    mouseout: function ($event) {
                      return _vm.handleMouseOut("dot")
                    },
                  },
                },
                [
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.setSlide(n - 1)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " + _vm._s(n) + "\n            "
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.settings.arrows && !_vm.settings.unagile
        ? _c("button", {
            staticClass: "agile__arrow agile__arrow--prev",
            attrs: {
              disabled: _vm.currentSlide === 0 && !_vm.settings.infinite,
            },
            domProps: { innerHTML: _vm._s(_vm.settings.prevArrow) },
            on: { click: _vm.prevSlide },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.settings.arrows && !_vm.settings.unagile
        ? _c("button", {
            staticClass: "agile__arrow agile__arrow--next",
            attrs: {
              disabled:
                _vm.currentSlide === _vm.slidesCount - 1 &&
                !_vm.settings.infinite,
            },
            domProps: { innerHTML: _vm._s(_vm.settings.nextArrow) },
            on: { click: _vm.nextSlide },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }