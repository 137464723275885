/**
 * @author Kyles Light
 * @email kuilin@yangqianguan.com
 * @date 2017-12-15 18:44:54.747
 * @desc generated by gulp
 */

import GeneralConfigKey from 'moneycan/common/constant/general-config-key';
import Common from 'moneycan/common/resource/common';

import YqgAgile from './yqg-agile.vue'; // eslint-disable-line

export default {
    name: 'EasycashIndo',

    title: 'Easycash',

    components: {YqgAgile},

    head() {
        return {};
    },

    data() {
        return {
            notice: ''
        };
    },

    mounted() {
        Common.getGeneralConfig({keys: GeneralConfigKey.TBK})
            .then(({data: {body: {configs: [{configValue}]}}}) => {
                this.notice = configValue;
            })
            .catch(x => x);
    },

    methods: {
        openAndroidModal() {
            window.location.href = 'https://download.geteasycash.asia/easycash.apk';
        }
    }
};
