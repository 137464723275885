var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "easycash-indo" }, [
    _c(
      "section",
      { staticClass: "banner" },
      [
        _c(
          "yqg-agile",
          {
            attrs: {
              infinite: true,
              speed: 500,
              timing: "linear",
              fade: true,
              "autoplay-speed": 6000,
              autoplay: true,
            },
          },
          [
            _c("div", { staticClass: "slide slide-1" }, [
              _c("div", { staticClass: "slide-content" }, [
                _c("h1", [
                  _vm._v(
                    "Easycash adalah platform Pinjaman P2P untuk setiap orang dapat memberi dan mengajukan pinjaman"
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "slide slide-2" }, [
              _c("div", { staticClass: "slide-content" }, [
                _c("h1", [
                  _vm._v(
                    "Easycash memiliki misi membantu dan membuat hidup semua orang jauh lebih mudah"
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "slide slide-3" }, [
              _c("div", { staticClass: "slide-content" }, [
                _c("h1", [
                  _vm._v(
                    "Easycash adalah Pinjaman P2P yang diatur oleh Otoritas Jasa Keuangan"
                  ),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm.notice
          ? _c("div", { staticClass: "notice" }, [
              _c(
                "div",
                { staticClass: "notice-card" },
                [
                  _c("antd-icon", {
                    staticClass: "icon-sound",
                    attrs: { type: "sound-fill" },
                  }),
                  _vm._v(
                    "\n                " + _vm._s(_vm.notice) + "\n            "
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _vm._m(3),
    _vm._v(" "),
    _vm._m(4),
    _vm._v(" "),
    _vm._m(5),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "title l-between" }, [
      _c("img", {
        staticClass: "easycash-logo",
        attrs: { src: require("./img/logo.png"), alt: "easycash-logo" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "customer-protection" }, [
      _c("h2", { staticClass: "title" }, [_vm._v("PERLINDUNGAN PELANGGAN")]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("p", [
          _vm._v(
            "Peminjam diharapkan menghindari Resiko kredit atau gagal bayar yang timbul dari pinjaman easycash."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Yang dimana mengakibatkan penyelenggara akan menguhubungi kontak darurat yang dicantumkan melalui pengisian formulir pada pengajuan pinjaman di aplikasi Easycash."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Dan juga peminjam dapat terlapor sebagai kredit macet pada SLIK ( Sistem Layanan Informasi Keuangan ) yang dikelola oleh OJK , dimana akan merekam riwayat kesehatan finansial masyarakat Indonesia yang nantinya dapat mempengaruhi dari laporan kesehatan finansial peminjam."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "us" }, [
      _c("h2", { staticClass: "title" }, [_vm._v("TENTANG KAMI")]),
      _vm._v(" "),
      _c("div", { staticClass: "content l-between" }, [
        _c("img", {
          staticClass: "l us-pic",
          attrs: { src: require("./img/us.png"), alt: "about-us-image" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [
          _c("p", [
            _vm._v(
              " Easycash adalah platform pinjaman P2P yang membantu semua orang untuk berinvestasi dan meminjam uang dengan\n                    mudah, nyaman dan aman. Easycash dikembangkan dan dikelola oleh PT. Indonesia "
            ),
            _c("b", [_vm._v("Fintopia")]),
            _vm._v(" Technology.\n                "),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Fintopia")]),
            _vm._v(
              " mematuhi Peraturan Otoritas Jasa Keuangan (POJK) Nomor 77 / POJK.01 / 2016 tentang Jasa Pinjam Meminjam\n                    Uang Berbasis Teknologi Informasi. Oleh karena itu, "
            ),
            _c("b", [_vm._v("Fintopia")]),
            _vm._v(
              " berkomitmen untuk selalu memperhatikan perlindungan\n                    konsumen dengan menjunjung tinggi integritas dan kepatuhan terhadap ketentuan hukum di Indonesia. Kami,\n                    bersama OJK, terus menjaga kepentingan terbaik untuk seluruh konsumen kami."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [
              _vm._v(
                "Kami mengerti perlindungan yang Pemberi Pinjaman butuhkan."
              ),
            ]),
            _vm._v(
              "\n                    Pemberian pinjaman yang Anda lakukan akan mendapatkan perlindungan yang biasa didapatkan oleh Pemberi Pinjaman profesional.\n                    Kami melakukan analisa kredit atas setiap Peminjam yang melakukan Pengajuan peminjaman guna menyaring\n                    Peminjam yang belum siap, tidak layak, atau usaha fiktif serta mencegah adanya informasi material yang\n                    tidak benar atau yang tidak diungkapkan. Dana pinjaman akan kami setorkan kepada Peminjam setelah Peminjam\n                    menandatangani dokumen-dokumen legal yang ditujukan untuk "
            ),
            _c("b", [_vm._v("melindungi pemberi pinjaman")]),
            _vm._v(".\n                "),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "features" }, [
      _c("h2", { staticClass: "title" }, [_vm._v("FITUR")]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "l-between" }, [
          _c("div", { staticClass: "l feature l-between" }, [
            _c("img", {
              staticClass: "l feature-pic",
              attrs: { src: require("./img/mins.png"), alt: "feature-image" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "r text" }, [
              _c("h3", [_vm._v("Cek kelayakan dalam 10 menit")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Proses verifikasi semua dilakukan secara otomatis melalui sistem yang canggih."
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "r feature l-between" }, [
            _c("img", {
              staticClass: "l feature-pic",
              attrs: { src: require("./img/funds.png"), alt: "feature-image" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "r text" }, [
              _c("h3", [_vm._v("Dana cair dalam rekening dalam waktu 24 jam")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Dengan dukungan dari BCA, Mandiri, BNI dan BRI, kami menawarkan layanan transfer uang 24 jam.\n                        "
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "l-between" }, [
          _c("div", { staticClass: "r feature l-between" }, [
            _c("img", {
              staticClass: "l feature-pic",
              attrs: { src: require("./img/ktp.png"), alt: "feature-image" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "r text" }, [
              _c("h3", [_vm._v("Pinjaman dengan Ponsel dan KTP")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Anda tidak perlu meminjam dengan jaminan apa pun, hanya perlu ponsel dan KTP, mengajukan pinjaman\n                            online tidak pernah semudah itu."
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "r feature l-between" }, [
            _c("img", {
              staticClass: "l feature-pic",
              attrs: {
                src: require("./img/no-hidden-fees.png"),
                alt: "feature-image",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "r text" }, [
              _c("h3", [_vm._v("Tidak ada biaya tersembunyi")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Anda dapat menemukan setiap detail harga di aplikasi Easycash. Kecuali kalau kita tidak mengenakan\n                            biaya lagi."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "process" }, [
      _c("h2", { staticClass: "title" }, [_vm._v("PROSES")]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "wrap-part l-around" }, [
          _c("div", { staticClass: "card" }, [
            _c("span", { staticClass: "sign" }, [_vm._v("1")]),
            _vm._v(" "),
            _c("h3", [_vm._v("Pengajuan")]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v(
                "Download Easycash dari Google Play dan isi formulir pada Aplikasi"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card" }, [
            _c("span", { staticClass: "sign" }, [_vm._v("2")]),
            _vm._v(" "),
            _c("h3", [_vm._v("Pengecekan Kredit")]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v(
                "Sistem secara otomatis akan memeriksa informasi yang Anda berikan"
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "wrap-part l-around" }, [
          _c("div", { staticClass: "card" }, [
            _c("span", { staticClass: "sign" }, [_vm._v("3")]),
            _vm._v(" "),
            _c("h3", [_vm._v("Persetujuan")]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v(
                "Setelah permohonan pinjaman disetujui. Dana akan dicairkan ke rekening bank yang Anda cantumkan"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card" }, [
            _c("span", { staticClass: "sign" }, [_vm._v("4")]),
            _vm._v(" "),
            _c("h3", [_vm._v("Pengembalian")]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v(
                "Ikuti instruksi pembayaran di aplikasi, dan bayar tagihan tepat waktu untuk mendapatkan nilai kredit\n                        yang bagus"
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "contact" }, [
      _c("div", { staticClass: "content l-between" }, [
        _c("div", { staticClass: "contact-time" }, [
          _c("p", [_vm._v("Layanan Pelanggan:(021) 50 2000 60")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Jam Kerja: Jam Kerja(Senin - Jumat) 9:00 AM - 5:00 PM / Sabtu 9:00 AM - 5:00 PM"
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "contact-desc" }, [
          _c("h2", { staticClass: "title" }, [_vm._v("PERHATIAN")]),
          _vm._v(" "),
          _c("ol", { staticClass: "desc-list" }, [
            _c("li", [
              _vm._v(
                "Layanan Pinjam Meminjam Uang Berbasis Teknologi Informasi (“Pinjaman Fintech”) merupakan bentuk persetujuan\n                        dan kesepakatan secara keperdataan antara Pemberi Pinjaman dan Penerima Pinjaman, sehingga segala\n                        bentuk risiko dan akibat hukum yang timbul karenanya sepenuhnya akan ditanggung oleh masing-masing\n                        pihak.\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Resiko Kredit atau Gagal Bayar yang timbul dari Pinjaman Fintech sepenuhnya menjadi tanggungjawab dari\n                        Pemberi Pinjaman. Tidak ada lembaga atau otoritas negara yang bertanggung jawab atas Risiko Kredit\n                        atau Gagal Bayar tersebut atau memberikan kompensasi atas kerugian atau konsekuensi lainnya yang\n                        timbul sehubungan dengan hal tersebut dalam bentuk apapun.\n\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Penyelenggaran dengan persetujuan dari setiap Pengguna (baik Pemberi Pinjaman maupun Penerima Pinjaman)\n                        mengakses, memperoleh, menyimpan, mengelola dan/atau menggunakan data pribadi Pengguna (“Pemanfaatan\n                        Data) pada atau di dalam benda, perangkat elektronik (termasuk smartphone atau telepon seluler),\n                        perangkat keras (hardware) maupun lunak (software), dokumen elektronik, aplikasi atau sistem elektronik\n                        milik Pengguna atau yang dikuasai oleh Pengguna, dengan memberitahukan tujuan, Batasan, dan mekanisme\n                        Pemanfaatan Data tersebut kepada Pengguna yang bersangkutan sebelum memperoleh persetujuan yang dimaksud.\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Pemberi Pinjaman yang belum memiliki pengetahuan dan pengalaman dalam hal Pinjam Meminjam Berbasis Teknologi\n                        Informasi atau Pinjaman Fintech, disarankan untuk tidak menggunakan layanan Pinjaman Fintech ini.\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Penerima Pinjaman wajib untuk mempertimbangkan tingkat suku bunga pinjaman serta biaya-biaya lainnya\n                        sesuai dengan kemampuannya dalam membayar pinjaman sebelum menggunakan fasilitas Pinjaman Fintech.\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Setiap kecurangan yang timbul pada Pinjaman Fintech akan tercatat secara digital di dunia maya dan dapat\n                        diketahui masyarakat luas melalui media sosial serta dapat menjadi alat bukti hukum yang sah menurut\n                        peraturan perundang-undangan yang berlaku tentang informasi dan transaksi elektronik dalam proses\n                        penyelesaian sengketa dan penegakan hukum.\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Masyarakat Pengguna wajib membaca dan memahami informasi ini sebelum mengambil keputusan untuk menjadi\n                        Pemberi Pinjaman ataupun menjadi Penerima Pinjaman. Keputusan Pengguna untuk menggunakan fasilitas\n                        Pinjaman Fintech ini membuktikan bahwa Pengguna telah memahami Informasi ini.\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Pemerintah, yaitu dalam hal ini Otoritas Jasa Keuangan, tidak bertanggung jawab atas setiap pelanggaran\n                        atau ketidakpatuhan oleh Pengguna, baik Pemberi Pinjaman maupun Penerima Pinjaman (baik karena kesengajaan\n                        atau kelalaian Pengguna) terhadap ketentuan peraturan perundang-undangan maupun kesepakatan atau\n                        perikatan antara Penyelenggara dengan Pemberi Pinjaman dan/atau Penerima Pinjaman.\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Setiap transaksi dan kegiatan pinjam meminjam atau pelaksanaan kesepakatan mengenai pinjam meminjam antara\n                        atau yang melibatkan Penyelenggara, Pemberi Pinjaman dan/atau Penerima Pinjaman wajib dilakukan melalui\n                        escrow account dan virtual account sebagaimana diwajibkan berdasarkan Peraturan Otoritas Jasa Keuangan\n                        Nomor 77/POJK.01/2016 tentang Layanan Pinjam Meminjam Uang Berbasis Teknologi Informasi. Pelanggaran\n                        atau ketidakpatuhan terhadap ketentuan tersebut merupakan bukti telah terjadinya pelanggaran hukum\n                        oleh Penyelenggara sehingga Penyelenggara wajib menanggung ganti rugi yang diderita oleh masing-masing\n                        Pengguna sebagi akibat langsung dari pelanggaran hukum tersebut di atas tanpa mengurangi hak Pengguna\n                        yang menderita kerugian tersebut menurut Kitab Undang-Undang Hukum Perdata.\n                    "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "contact-footer" }, [
          _c("div", [
            _c("p", { staticClass: "desc copy-right" }, [
              _vm._v("©PT Indonesia Fintopia Technology"),
            ]),
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "contact-logo",
            attrs: { src: require("./img/contact.png"), alt: "feature-image" },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }